import React, { Fragment, useEffect, useState } from "react";
import share from "../../../public/images/share.png";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
import Modal from "react-responsive-modal";
import { closeIcon, PriceConvertor } from "../../../common_components/CommonFields";
import Image from 'next/image';
import Link from 'next/link';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { FaClipboard } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as ServiceAction from "../../../actions/serviceActions";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import Analytics from "../../../common_components/Common/Analytics";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as fasBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';


import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const cache = createCache({
    key: 'css',
    prepend: true
});




export default function HomePageVehicledetailsCard({ vehicleData, label, subtype }) {

    const dispatch = useDispatch();

    const { addlocal } = bindActionCreators(ServiceAction, dispatch);


    const baseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL;
    const [openShareModal, setOpenShareModal] = useState("");

    const [savedVehiclesData, setSavedVehiclesData] = useState(null);
    const [isBookmark, setIsBookmark] = useState(false);
    const [top_speed, setTop_speed] = useState("-");
    const [range, setRange] = useState("-");
    const [totalPrice, setTotalPrice] = useState();
    const [waitingPeriod, setWaitingPeriod] = useState();
    const [waitingTip, setWaitingTip] = useState();

    useEffect(() => {
        if (!isBookmark) {

            // const d = ;

            setSavedVehiclesData(localStorage.getItem("saved_vehicles"));
            setIsBookmark(true);
        }
    }, [isBookmark]);

    useEffect(() => {
        if (vehicleData) {
            const topSpeedData = vehicleData.top_speed.find(
                (specData) => specData.specification_code === "top_speed"
            );
            const rangeData = vehicleData.true_range.find(
                (specData) => specData.specification_code === "true_range"
            );

            if (rangeData) {
                setRange(rangeData.specification_value_number + " " + "km");
            } else {
                setRange("-");
            }
            if (topSpeedData) {
                setTop_speed(
                    topSpeedData.specification_value_number + " " + "km/h"
                );
            } else {
                setTop_speed("-");
            }
            const value = PriceConvertor(vehicleData.total_price);
            setTotalPrice(value)
            if (vehicleData.waiting_period_type === 2) {
                let waitingperiodEle = vehicleData.waiting_period / 30
                let waitingData = waitingperiodEle === 0 ? "immediate" : waitingperiodEle === 1 ? waitingperiodEle + "M" : waitingperiodEle + "M"
                let text = waitingperiodEle === 0 ? "immediate" : waitingperiodEle === 1 ? waitingperiodEle + " Month" : waitingperiodEle + " Months"
                setWaitingTip(text)
                setWaitingPeriod(waitingData);
                // console.log(waitingperiodEle)
            }
            else {
                let waitingPeriodEle = vehicleData.waiting_period === 0 ? "immediate" : vehicleData.waiting_period === 1 ? vehicleData.waiting_period + "D" : vehicleData.waiting_period + "D"
                let text = vehicleData.waiting_period === 0 ? "immediate" : vehicleData.waiting_period === 1 ? vehicleData.waiting_period + " Day" : vehicleData.waiting_period + " Days"
                setWaitingTip(text)
                setWaitingPeriod(waitingPeriodEle)
            }
        }
    }, [vehicleData]);

    const saveVehicle = () => {
        let savedData = JSON.parse(localStorage.getItem("saved_vehicles"));
        // console.log(savedData)
        if (!savedData || savedData.length === 0) {
            savedData = [];
            savedData.push(vehicleData);
        } else {
            savedData.push(vehicleData);
        }
        localStorage.setItem("saved_vehicles", JSON.stringify(savedData));
        addlocal();
        setIsBookmark(false);
    };

    const toggleShareModal = (data) => {
        setOpenShareModal(data._id);
    };

    const checkSavedData = () => {
        let result = false;
        if (savedVehiclesData) {
            JSON.parse(savedVehiclesData).forEach((e) => {
                if (e._id === vehicleData._id) {
                    return (result = true);
                }
            });
        }
        return result;
    };

    const isAvailable = checkSavedData();

    const removeVehicle = () => {
        let savedData = JSON.parse(localStorage.getItem("saved_vehicles"));
        // console.log(savedData)
        if (isAvailable) {
            const filteredArr = savedData.filter((data) => {
                // if (data._id !== vehicleData._id) {
                //   return data;
                // }
                const temp = data._id !== vehicleData._id;
                // console.log(temp)
                return temp;
            });

            localStorage.setItem("saved_vehicles", JSON.stringify(filteredArr));
            addlocal();
            setIsBookmark(false);
        }
    };

    const handleClipboard = (slug) => {
        navigator.clipboard.writeText(
            `${baseUrl}/product/${slug}`
        );
        toast.success('Link Copied!!!');
        setOpenShareModal('');
    };

    const HandleEvents = (data) => {
        const Analyticsprops = {
            GA: true,
            title: `${subtype}-Events`,
            category: 'Home Page-Events',
            action: `vehicle ${data} `,
            label: `${vehicleData.companyData && vehicleData.companyData?.vehicle_prefix
                ? vehicleData.companyData?.vehicle_prefix
                : vehicleData.companyData.title ? vehicleData.companyData.title : ''}${' '}
      ${vehicleData && vehicleData.title} ${data} clicked!`,
        }
        Analytics(Analyticsprops)
    }


    const renderShareModal = () => {
        return (
            <Fragment>
                <Modal
                    center
                    open={openShareModal ? true : false}
                    closeIcon={closeIcon}
                    onClose={() => setOpenShareModal('')}
                >
                    <div style={{ minWidth: '280px', textAlign: 'center' }}>
                        <div className='px-5 py-2 fs-5 fw-bold mb-2'>Share via :</div>
                        {vehicleData && vehicleData.companyData && (
                            <ul>
                                <li>
                                    <span className='me-2'>
                                        <ReactTooltip id={vehicleData?._id} place='top' effect='solid'>
                                            Copy Link
                                        </ReactTooltip>
                                        <FaClipboard
                                            data-tip
                                            data-for={vehicleData?._id}
                                            style={{ fontSize: '2.3rem', cursor: 'pointer' }}
                                            onClick={(e) => handleClipboard(vehicleData?.slug)}
                                        />
                                    </span>
                                    <span className='me-2'>
                                        <FacebookShareButton
                                            url={`${baseUrl}/product/${vehicleData.slug}`}

                                            quote={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title + ' ' + vehicleData.title
                                                }`}
                                        // hashtag={`#${
                                        //   vehicleData.companyData?.vehicle_prefix + vehicleData.title
                                        // }`}
                                        >
                                            <FacebookIcon size={'2.5rem'} round />
                                        </FacebookShareButton>
                                    </span>
                                    <span className='me-2'>
                                        <WhatsappShareButton
                                            url={`${baseUrl}/product/${vehicleData.slug}`}
                                            title={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title + ' ' + vehicleData.title
                                                }`}
                                            separator=' :: '
                                        >
                                            <WhatsappIcon size={'2.5rem'} round />
                                        </WhatsappShareButton>
                                    </span>
                                    <span className='me-2'>
                                        <TwitterShareButton
                                            url={`${baseUrl}/product/${vehicleData.slug}`}
                                            title={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title + ' ' + vehicleData.title
                                                }`}
                                        // hashtags={`[#${
                                        //   vehicleData.companyData.title.toLowerCase() +
                                        //   vehicleData.title.toLowerCase()
                                        // }]`}
                                        >
                                            <TwitterIcon size={'2.5rem'} round />
                                        </TwitterShareButton>
                                    </span>
                                    <span className='me-2'>
                                        <TelegramShareButton
                                            title={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title + ' ' + vehicleData.title
                                                }`}
                                            url={`${baseUrl}/product/${vehicleData.slug}`}
                                        >
                                            <TelegramIcon size={'2.5rem'} round />
                                        </TelegramShareButton>
                                    </span>
                                    <LinkedinShareButton
                                        title={`${vehicleData?.companyData?.vehicle_prefix ? vehicleData?.companyData?.vehicle_prefix : vehicleData?.companyData?.title + ' ' + vehicleData.title
                                            }`}
                                        url={`${baseUrl}/product/${vehicleData.slug}`}
                                    >
                                        <LinkedinIcon size={'2.5rem'} round />
                                    </LinkedinShareButton>
                                </li>
                            </ul>
                        )}
                    </div>
                </Modal>
            </Fragment>
        );
    };


    return (
        // <LazyLoad height={1500} offset={100} placeholder='Loading' >
        // <Fragment>
        //   {vehicleData && (
        //     <div className="new-vehicle-card pb-3 h-100 shadow home-vehicle-card">
        //       <div className="mb-1 px-3 pt-3 pb-2">
        //         {label && 
        //         <span className="new-lbl border">{label || " "}</span> }
        //         <span
        //           className="float-end"
        //           onClick={(e) => setOpenShareModal(vehicleData._id)}
        //         >
        //           <Image
        //             src={share}
        //             className="c-pointer"
        //             alt="share"
        //             width="18px"
        //             height="18px"
        //             onClick={(e) => toggleShareModal(vehicleData)}
        //           />
        //         </span>
        //       </div>
        //       <Link href={`/product/${vehicleData && vehicleData.slug}`}>
        //         <a> 
        //           <img
        //           src={vehicleData.image[0]}
        //           className="px-3 mb-3 c-pointer"
        //           alt=""
        //           width={"100%"}
        //           height={"160px"}
        //           style={{ objectFit: "cover" }}
        //         /> 
        //         </a>
        //       </Link>
        //       <hr className="m-0" />
        //       <div className="row w-100 mx-0 px-3 pt-2">
        //         <div className="col-11 px-0 pb-1 d-flex fs-6 align-items-center">
        //           {vehicleData.companyData && vehicleData.companyData.title
        //             ? vehicleData.companyData.title
        //             : ""}{" "}<br/>
        //           {vehicleData && vehicleData.title}
        //         </div>
        //         <div
        //           className="col-1 pt-1 px-0 text-end"
        //           onClick={() => {
        //             isAvailable ? removeVehicle() : saveVehicle();
        //           }}
        //         >
        //           <i
        //             className={classNames(
        //               {
        //                 "fas fa-bookmark bookmark-icon-selected": checkSavedData(),
        //               },
        //               {
        //                 "far fa-bookmark bookmark-icon": !checkSavedData(),
        //               }
        //             )}
        //           ></i>
        //         </div>
        //         {/* {vehicleData.rating >= 0 && ( */}
        //         <>
        //           {/* <div className='col-12 px-0 pb-2 d-flex align-items-center'>
        //             <StarRatings
        //               // rating={parseFloat(vehicleData.rating)}
        //               numberOfStars={5}
        //               starDimension='20px'
        //               starSpacing='1px'
        //               name='rating'
        //               starHoverColor='#FF9529'
        //               starRatedColor='#FDCC0D'
        //             />
        //             {/* <div className='rating-count mt-1'>
        //               {'(' + vehicleData.rating + ')'}
        //             </div> 
        //           </div> */}
        //         </>
        //         {/* )} */}
        //         <div className="price-section px-0">
        //           <h6 className="d-inline ">
        //             {/* ₹ {vehicleData && vehicleData.total_price.toLocaleString()} */}
        //             ₹ {totalPrice}
        //             <span className="price-desc ps-1">onwards</span>
        //           </h6>
        //           {/* <div className='price-desc mt-1'>
        //             On Road Price in, {vehicleData && vehicleData.city}
        //           </div> */}
        //         </div>
        //         {vehicleData && vehicleData.top_speed || vehicleData.true_range ? (
        //           <div className="px-2 w-100 pt-2">
        //             <div className="row pt-1 border-top pt-2 ">
        //               <div className="w-50 mb-1 oth-details px-0">Top Speed</div>
        //               <div className="w-50 mb-1 oth-details px-0 text-end">
        //                 {top_speed}
        //               </div>
        //               <div className="w-50 mb-1 oth-details px-0">Range</div>
        //               <div className="w-50 mb-1 oth-details px-0 text-end">
        //                 {range}
        //               </div>
        //               <div className="w-50 mb-1 oth-details px-0">
        //                 Waiting Period
        //               </div>
        //               <div className="w-50 mb-1 oth-details px-0 text-end">
        //               {vehicleData && waitingPeriod}
        //               </div>
        //             </div>
        //           </div>
        //         ) : null}

        //         {/* <span className='small-lbl p-0 mt-2 text-dark'>Critiqeu rating</span>
        //     <Progress percent={50} showInfo={false} strokeColor={'#2ecc71'} />
        //     <span className='small-lbl p-0 mt-1 text-dark'>User rating</span>
        //   <Progress percent={50} showInfo={false} strokeColor={'#fcbf00'} /> */}
        //         {renderShareModal()}
        //       </div>
        //     </div>
        //   )}
        // </Fragment>
        //  </LazyLoad>

        <Fragment>
            {vehicleData && (
                <div
                    className={classNames(
                        'new-vehicle-card h-100 border  rounded-2 home-vehicle-card',
                        // {
                        //   'new-vehicle-card-width': similiarproduct,
                        // }
                    )}
                >
                    <div
                        className={`${label
                            ? 'mb-1 px-3 pt-3 pb-2 d-flex justify-content-between'
                            : 'mb-1 px-3 pt-3 pb-2 text-end'
                            }`}
                    >
                        {/* <div className='mb-1 px-3 pt-3 pb-2 text-end'> */}
                        {label && <span className='new-lbl border'>{label || ''}</span>}
                        {/* {upcominglabel && (
              <span className='new-lbl border'>{'Upcoming' || ' '}</span>
            )} */}
                        <span
                            className=''
                            onClick={(e) => { setOpenShareModal(vehicleData._id); HandleEvents("Share Button") }}
                        >
                            <Image
                                src={share}
                                className='c-pointer'
                                alt='share'
                                width='18px'
                                height='18px'
                                objectFit="contain"
                                loading="lazy"
                                onClick={(e) => toggleShareModal(vehicleData)}
                            />
                        </span>
                    </div>
                    <Link href={`/product/${vehicleData && vehicleData.slug}`}>
                        <a href={`/product/${vehicleData && vehicleData.slug}`}>
                            <Image
                                src={vehicleData.image[0]}
                                className='px-3 mb-3 vehicle-img'
                                alt={`${vehicleData && vehicleData.title}-img`}
                                width='300px'
                                height='190px'
                                name='Vehicle-img'
                                sizes="320 640 750"
                                layout='fixed'
                                loading='lazy'
                                // layout='fill'
                                // sizes="50vw"
                                // layout='fill'
                                // height={'160px'}
                                // style={{ objectFit: 'contain' }}
                                onClick={(e) => HandleEvents("logo")}
                            />
                        </a>
                    </Link>

                    {/* <hr className='m-0' /> */}
                    <div className='w-100 mx-0 px-3 pt-2  pb-2'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <Link href={`/product/${vehicleData && vehicleData.slug}`}>
                                <a href={`/product/${vehicleData && vehicleData.slug}`}>
                                    <h2
                                        className='fw-400 px-0 pb-1 d-flex  align-items-center c-pointer line-clamp-1'
                                        style={{ fontSize: "1.1rem" }}
                                        onClick={() => HandleEvents("name")}
                                    >
                                        {vehicleData.companyData && vehicleData.companyData?.vehicle_prefix
                                            ? vehicleData.companyData?.vehicle_prefix
                                            : vehicleData.companyData.title ? vehicleData.companyData.title : ''}{' '}
                                        {vehicleData && vehicleData.title}
                                    </h2>
                                </a>
                            </Link>
                            {/* -------------------------------------------------------------- */}
                            <div
                                className='text-end flex-shrink-0 border rounded-circle lh-1 p-2'
                                onClick={() => {
                                    isAvailable ? removeVehicle() : saveVehicle();
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={checkSavedData() ? fasBookmark : farBookmark}
                                    className={checkSavedData() ? 'bookmark-icon-selected' : 'bookmark-icon'}
                                    style={{ width: '1em' }}
                                />
                            </div>

                            {/* -------------------------------------------------------------- */}
                        </div>
                        {/* {vehicleData.rating >= 0 && ( */}
                        {/* <>
              <div className='col-12 px-0 pb-2 d-flex align-items-center'>
                <StarRatings
                  rating={parseFloat(vehicleData.rating)}
                  numberOfStars={5}
                  starDimension='20px'
                  starSpacing='1px'
                  name='rating'
                  starHoverColor='#FF9529'
                  starRatedColor='#FDCC0D'
                />
                <div className='rating-count mt-1'>
                  {'(' + vehicleData.rating + ')'}
                </div>
              </div>
            </> */}
                        {/* )} */}
                        <div className='price-section px-0'>
                            <h3 className='d-inline fs-6'>
                                ₹ {totalPrice}
                                <span className='price-desc ps-1 text-dark'>Onwards</span>
                            </h3>
                            {/* <div className='price-desc mt-1'>
                On Road Price in, {vehicleData && vehicleData.city}
              </div> */}
                        </div>
                        {/* <div className='d-flex gap-4 text-decoration-underline text-primary pt-2 '>
              <Link replace
                href={{
                  pathname: `/product/${vehicleData && vehicleData.slug}`,
                  query: { section: 'dealersSection' },
                }}
              >
                <a className='small m-0'>View dealers</a>
              </Link>
              <Link replace
                href={{
                  pathname: `/product/${vehicleData && vehicleData.slug}`,
                  query: { section: 'ownerSection' },
                }}
              >
                <a className='small  m-0'>View owners</a>
              </Link>
            </div> */}
                    </div>
                    {/* <hr className='color-d-gray ng-white hr-margin mt-0' /> */}
                    {(vehicleData && vehicleData.top_speed) || vehicleData.true_range ? (
                        <div className=''>
                            <hr
                                className='mb-0 mt-1'
                                style={{

                                    borderWidth: '1',
                                    color: 'gray',
                                    backgroundolor: 'gray',
                                }}
                            />
                            {/* <div className='row pt-2 ps-2 pe-2 '> */}
                            <div className='d-flex px-3 py-3 justify-content-between'>
                                <div className=' d-flex '>
                                    {/* <div className='col-4 d-flex'> */}


                                    {/* <BsSpeedometer size={16} className='flex-shrink-0 mt-1' /> */}
                                    <div className=' text-center'>
                                        {/* <img
                      src='/images/Top Speed.png'
                      alt='range'
                      style={{ width: '20px', height: '20px' }}
                      className='flex-shrink-0 mt-1'
                    ></img> */}
                                        {/* <div className='ps-2 d-inline-block'> */}
                                        <p className='mb-1 color-d-gray fw-500 px-0' style={{ fontSize: '16px', color: '#1f1f29' }}>{top_speed}</p>
                                        <p className='float-none fw-400 mb-0 text-dark fs-12 px-0'>
                                            Top Speed
                                        </p>

                                    </div>
                                </div>
                                <div className=' d-flex '>
                                    {/* <div className='col-4 d-flex'> */}

                                    {/* <BsSpeedometer size={16} className='flex-shrink-0 mt-1' /> */}
                                    <div className='ps-2 text-center'>
                                        {/* <img
                      src='/images/RAnge.png'
                      alt='range'
                      style={{ width: '20px', height: '20px' }}
                      className='flex-shrink-0 mt-1'
                    ></img> */}
                                        {/* <div className='ps-2 d-inline-block'> */}
                                        <p className='mb-1 color-d-gray fw-500  px-0' style={{ fontSize: '16px', color: '#1f1f29' }}>{range}</p>
                                        <p className='float-none mb-0  fw-400 text-dark fs-12 px-0'>
                                            Range
                                        </p>

                                    </div>
                                </div>
                                <div className='d-flex '>
                                    {/* <div className='col-4 d-flex'> */}
                                    {/* <BsSpeedometer size={16} className='flex-shrink-0 mt-1' /> */}

                                    <div className='ps-2 text-center'>
                                        {/*<img
                      src='/images/Waiting.png'
                      alt='range'
                      style={{ width: '20px', height: '20px' }}
                      className='flex-shrink-0 mt-1'
                    ></img> */}
                                        {/* <div className='ps-2 d-inline-block'> */}
                                        <CacheProvider value={cache}>
                                            <ReactTooltip id={vehicleData?.title} place='top' effect='solid'>
                                                {waitingTip}
                                            </ReactTooltip>
                                        </CacheProvider>
                                        <p data-tip data-for={vehicleData?.title} className='mb-1  fw-500 px-0' style={{ fontSize: '16px', color: '#1f1f29' }}>{waitingPeriod}</p>
                                        <p className='float-none mb-0 fw-400 text-dark fs-12 px-0'>
                                            Waiting Period
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {/* <span className='small-lbl p-0 mt-2 text-dark'>Critiqeu rating</span>
        <Progress percent={50} showInfo={false} strokeColor={'#2ecc71'} />
        <span className='small-lbl p-0 mt-1 text-dark'>User rating</span>
      <Progress percent={50} showInfo={false} strokeColor={'#fcbf00'} /> */}
                    {/* </div> */}
                    {/* <hr
            className='mt-2'
            style={{
              height: '5px',
              borderWidth: '0',
              color: 'gray',
              backgroundolor: 'gray',
            }}
          />
          <div className='px-2 w-100 pb-2 mb-2'>
            <div className='row w-100 px-2'>
              <div className='custom-control compare  custom-checkbox d-flex align-items-center'>
                <input
                  type='checkbox'
                  value='Add to compare'
                  className='custom-control-input me-2'
                  id={vehicleData && vehicleData._id}
                  checked={getCheckBox.includes(vehicleData._id) ? true : false}
                  onChange={(e) => onGettingIdValue(e, vehicleData)}
                  onClick={(e) => HandleEvents(e.target.value)}
                />
                <label
                  className='custom-control-label color-d-black '
                  htmlFor={vehicleData && vehicleData._id}
                >
                  Add to compare
                </label>
              </div>
            </div>
            
          </div> */}
                    {renderShareModal()}
                </div>
            )}
        </Fragment>
    );
}

